
export default {
  name: "DynamicButton",
  props: {
    dynamicId: {
      type: String,
      default: "",
      required: false,
    },
    isSlot: {
      type: Boolean,
      default: false,
      required: false,
    },
    width: {
      type: String,
      default: "10rem",
      required: false,
    },
    text: {
      type: String,
      default: "",
      required: false,
    },
    icon: {
      type: String,
      default: "",
      required: false,
    },
    color: {
      type: String,
      default: "green",
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    submit: {
      type: Boolean,
      default: false,
      required: false,
    },
    spinning: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    iconUsed() {
      return this.icon !== ""
    },
  },
  methods: {
    returnClick() {
      this.$emit("clickEvent")
    },
  },
}
