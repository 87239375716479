
export default {
  name: "LoadingBar",
  props: {
    max: {
      type: Number,
      default: 100,
      required: false,
    },
    current: {
      type: Number,
      default: 0,
      required: false,
    },
    colour: {
      type: String,
      default: "purple",
      required: false,
    },
  },
  computed: {
    loadingWidth() {
      return `${parseFloat((this.current / this.max) * 100).toFixed(2)}%`
    },
  },
}
