
import { mapState } from "vuex"
export default {
  name: "Footer",
  data: () => ({
    showSuccess: false,
    inputs: [
      {
        title: "Sign up and get 10% off your first order",
        type: "email",
        required: true,
        showTitle: false,
        id: "email",
        name: "email",
        error: "",
        value: "",
        width: "95%",
      },
    ],
    hubspotScriptLoaded: false,
    s3Image: process.env.S3_IMAGE,
  }),
  computed: {
    ...mapState(["customerLoading", "isBiz"]),
    hasErrors() {
      return (
        this.inputs.some((input) => input.value.length === 0) ||
        this.inputs.some((input) => input.error.length > 0)
      )
    },
  },
  mounted() {
    const script = document.createElement("script")
    const formContainer =
      document.getElementsByClassName("footer__top--form")[0]
    script.type = "text/javascript"
    script.src = "https://js.hsforms.net/forms/v2.js"
    formContainer.appendChild(script)
    script.addEventListener("load", () => {
      this.hubspotScriptLoaded = true
    })
  },
  methods: {
    updateForm(form) {
      this.inputs = form
    },
  },
}
