import posthog from "posthog-js"
import Vue from "vue"

export default function ({ app: { router } }, inject) {
  posthog.init("phc_Rkpvnbr5B8QlmhubENWGDvL0uwRrs9ckusKm00yrkTa", {
    api_host: '"https://eu.i.posthog.com"',
    capture_pageview: false,
    capture_pageleave: true,
    autocapture: true,
    loaded: () => posthog.identify("unique_id"),
  })
  inject("posthog", posthog)
  router.afterEach((to) => {
    Vue.nextTick(() => {
      posthog.capture("$pageview", {
        $current_url: to.fullPath,
      })
    })
  })
}
